* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font: 400 14px/1 'Roboto', Arial, sans-serif;
  color: $grey-color-normal;
  background: $background-color;
  box-shadow: inset 0 15px 0 $primary-color;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  margin: 0;
}

dl, dt, dd, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  padding: 0 0 0 20px;
}

form, input, textarea, select {
  font: 400 16px/16px 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  resize: none;
}

img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: block;
  border: 0;
}

a {
  text-decoration: none;
  color: $grey-color-normal;
}

p {
  margin: 0;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

header, nav, section, main, article, aside, figure, footer, iframe, object, svg {
  display: block;
  margin: 0;
}

.clear:before,
.clear:after {
  content: "";
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  zoom: 1;
}

::-ms-clear {
  display: none;
}