@mixin media-query($device) {
  @media screen and (max-width: $device), screen and (max-device-width: $device) {
    @content;
  }
}

@mixin placeholder($color, $style: normal) {
  ::-webkit-input-placeholder {
    color: $color;
    font-size: 16px;
    font-style: $style;
  }
  :-moz-placeholder {
    color: $color;
    opacity: 1;
    font-size: 16px;
    font-style: $style;
  }
  ::-moz-placeholder {
    color: $color;
    opacity: 1;
    font-size: 16px;
    font-style: $style;
  }
  :-ms-input-placeholder {
    color: $color;
    font-size: 16px;
    font-style: $style;
  }
}

@mixin transition($transitions...) {
  transition: $transitions;
  -moz-transition: $transitions;
  -webkit-transition: $transitions;
  -o-transition: $transitions;
}

@mixin rotate($deg) {
  -webkit-transform: rotate(#{$deg});
  -moz-transform: rotate(#{$deg});
  -ms-transform: rotate(#{$deg});
  -o-transform: rotate(#{$deg});
  transform: rotate(#{$deg});
}

@mixin translate($val1, $val2) {
  -webkit-transform: translate(#{$val1}, #{$val2});
  -moz-transform: translate(#{$val1}, #{$val2});
  -ms-transform: translate(#{$val1}, #{$val2});
  -o-transform: translate(#{$val1}, #{$val2});
  transform: translate(#{$val1}, #{$val2});
}

@mixin bgsize($type) {
  -webkit-background-size: $type;
  -moz-background-size: $type;
  -o-background-size: $type;
  background-size: $type;
}

@mixin bgsizeNum($size1, $size2) {
  -webkit-background-size: $size1 $size2;
  -moz-background-size: $size1 $size2;
  -o-background-size: $size1 $size2;
  background-size: $size1 $size2;
}

@mixin transform-none() {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-flow($values: (row wrap)) {
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin justify-content($pos: center) {
  -webkit-justify-content: $pos;
  justify-content: $pos;
}

@mixin align-items($align: center) {
  -webkit-align-items: $align;
  align-items: $align;
}